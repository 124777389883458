import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { Collapse, Input, Button } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../Store/actions/index';
import * as ChangeAppLanguageFront from '../Layout/ChangeAppLanguageFront';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import { Icon } from "@material-ui/core";
import HeaderNotification from './headerNotification';
import Modals from '../Client/modals/modals';
import ModalSwitchCompany from '../Client/components/dashboard/modals/switchCompany';
import ModalPlanExpirePreWarning from '../Client/components/profile/modals/planExpirePreWarning';
import ModalLicenceExpiredWarning from '../Client/components/profile/modals/licenceExpiredWarning';
import ModalLicenceExpirePreWarning from '../Client/components/profile/modals/licenceExpirePreWarning';
import ModalBlockedUserRealTimeLogout from '../Client/components/profile/modals/blockedUserRealTimeLogout';
import ModalLogoutConfirmation from '../Client/components/profile/modals/logoutConfirmation';
// import NotificationComponent from './NotificationComponent';

let tlang = ChangeAppLanguageFront.translateLanguage;

const active_project_text = tlang('active_project_text') || 'Active Project';
const active_company_name = tlang('active_company_name') || 'Company';
const change_project_text = tlang('change_project_text') || 'Change Project';
const label_select_project = tlang('label_select_project') || 'Select project';
const topbar_link_profile = tlang('topbar_link_profile') || 'My Profile';
const topbar_link_logout = tlang('topbar_link_logout') || 'Logout';
const company_statistics = tlang('company_statistics') || 'Company Statistics';
const topbar_link_invoices = tlang('topbar_link_invoices') || 'Invoices';
const topbar_link_system_log = tlang('topbar_link_system_log') || 'System log';
const topbar_link_notification_setting = tlang('topbar_link_notification_setting') || 'Notification setting';
const topbar_link_email_setting = tlang('topbar_link_email_setting') || 'Email setting';
const clientmenu_add_project = tlang('clientmenu_add_project') || 'New project';
const clientmenu_view_project = tlang('clientmenu_view_project') || 'Project list';
const change_company = tlang('change_company') || 'Change Company';
const search_text = tlang('search_text') || 'Search';

const SrcInpStyl = {
	width: "98%",
	margin: "1% 0 2% 2%",
}

const en_us = `${process.env.PUBLIC_URL}/img/language/en_us.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;
const de = `${process.env.PUBLIC_URL}/img/language/de.png`;
const it = `${process.env.PUBLIC_URL}/img/language/it.png`;
const es = `${process.env.PUBLIC_URL}/img/language/es.png`;
const af = `${process.env.PUBLIC_URL}/img/language/af.png`;
const at = `${process.env.PUBLIC_URL}/img/language/at.png`;
const be = `${process.env.PUBLIC_URL}/img/language/be.png`;
const br = `${process.env.PUBLIC_URL}/img/language/br.png`;
const bg = `${process.env.PUBLIC_URL}/img/language/bg.png`;
const hr = `${process.env.PUBLIC_URL}/img/language/hr.png`;
const cz = `${process.env.PUBLIC_URL}/img/language/cz.png`;
const dk = `${process.env.PUBLIC_URL}/img/language/dk.png`;
const et = `${process.env.PUBLIC_URL}/img/language/et.png`;
const fi = `${process.env.PUBLIC_URL}/img/language/fi.png`;
const ge = `${process.env.PUBLIC_URL}/img/language/ge.png`;
const gr = `${process.env.PUBLIC_URL}/img/language/gr.png`;
const hu = `${process.env.PUBLIC_URL}/img/language/hu.png`;
const ind = `${process.env.PUBLIC_URL}/img/language/ind.png`;
const lv = `${process.env.PUBLIC_URL}/img/language/lv.png`;
const no = `${process.env.PUBLIC_URL}/img/language/no.png`;
const nl = `${process.env.PUBLIC_URL}/img/language/nl.png`;
const pl = `${process.env.PUBLIC_URL}/img/language/pl.png`;
const pt = `${process.env.PUBLIC_URL}/img/language/pt.png`;
const ro = `${process.env.PUBLIC_URL}/img/language/ro.png`;
const ru = `${process.env.PUBLIC_URL}/img/language/ru.png`;
const rs = `${process.env.PUBLIC_URL}/img/language/rs.png`;
const sk = `${process.env.PUBLIC_URL}/img/language/sk.png`;
const si = `${process.env.PUBLIC_URL}/img/language/si.png`;
const se = `${process.env.PUBLIC_URL}/img/language/se.png`;
const th = `${process.env.PUBLIC_URL}/img/language/th.png`;
const tr = `${process.env.PUBLIC_URL}/img/language/tr.png`;
const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const lt = `${process.env.PUBLIC_URL}/img/language/lt.png`;

const EnLng = () => (
	<span className="topbar__language-btn-title">
		<img src={en_us} alt="en" />
		<span>EN-US</span>
	</span>
);

const FrLng = () => (
	<span className="topbar__language-btn-title">
		<img src={fr} alt="fr" />
		<span>FR</span>
	</span>
);

const DeLng = () => (
	<span className="topbar__language-btn-title">
		<img src={de} alt="de" />
		<span>DE</span>
	</span>
);

const ItLng = () => (
	<span className="topbar__language-btn-title">
		<img src={it} alt="it" />
		<span>IT</span>
	</span>
);

const GbukLng = () => (
	<span className="topbar__language-btn-title">
		<img src={gb} alt="gb" />
		<span>EN-UK</span>
	</span>
);

const EsLng = () => (
	<span className="topbar__language-btn-title">
		<img src={es} alt="es" />
		<span>ES</span>
	</span>
);

const AfLng = () => (
	<span className="topbar__language-btn-title">
		<img src={af} alt="af" />
		<span>AF</span>
	</span>
);

const AtLng = () => (
	<span className="topbar__language-btn-title">
		<img src={at} alt="at" />
		<span>DE-AT</span>
	</span>
);

const BeLng = () => (
	<span className="topbar__language-btn-title">
		<img src={be} alt="be" />
		<span>FR-BE</span>
	</span>
);

const BrLng = () => (
	<span className="topbar__language-btn-title">
		<img src={br} alt="br" />
		<span>PT-BR</span>
	</span>
);

const BgLng = () => (
	<span className="topbar__language-btn-title">
		<img src={bg} alt="bg" />
		<span>BG</span>
	</span>
);

const HrLng = () => (
	<span className="topbar__language-btn-title">
		<img src={hr} alt="hr" />
		<span>HR</span>
	</span>
);

const CzLng = () => (
	<span className="topbar__language-btn-title">
		<img src={cz} alt="cz" />
		<span>CS-CZ</span>
	</span>
);

const DkLng = () => (
	<span className="topbar__language-btn-title">
		<img src={dk} alt="dk" />
		<span>DA-DK</span>
	</span>
);

const EtLng = () => (
	<span className="topbar__language-btn-title">
		<img src={et} alt="et" />
		<span>ET</span>
	</span>
);

const FiLng = () => (
	<span className="topbar__language-btn-title">
		<img src={fi} alt="fi" />
		<span>FI</span>
	</span>
);

const GeLng = () => (
	<span className="topbar__language-btn-title">
		<img src={ge} alt="ge" />
		<span>KA-GE</span>
	</span>
);

const GrLng = () => (
	<span className="topbar__language-btn-title">
		<img src={gr} alt="gr" />
		<span>EL-GR</span>
	</span>
);

const HuLng = () => (
	<span className="topbar__language-btn-title">
		<img src={hu} alt="hu" />
		<span>HU</span>
	</span>
);

const InLng = () => (
	<span className="topbar__language-btn-title">
		<img src={ind} alt="ind" />
		<span>HI-IN</span>
	</span>
);

const LvLng = () => (
	<span className="topbar__language-btn-title">
		<img src={lv} alt="lv" />
		<span>LV</span>
	</span>
);

const LtLng = () => (
	<span className="topbar__language-btn-title">
		<img src={lt} alt="lt" />
		<span>LT</span>
	</span>
);

const NlLng = () => (
	<span className="topbar__language-btn-title">
		<img src={nl} alt="nl" />
		<span>NL-NL</span>
	</span>
);

const NoLng = () => (
	<span className="topbar__language-btn-title">
		<img src={no} alt="no" />
		<span>NB-NO</span>
	</span>
);

const PlLng = () => (
	<span className="topbar__language-btn-title">
		<img src={pl} alt="pl" />
		<span>PL</span>
	</span>
);

const PtLng = () => (
	<span className="topbar__language-btn-title">
		<img src={pt} alt="pt" />
		<span>PT-PT</span>
	</span>
);

const RoLng = () => (
	<span className="topbar__language-btn-title">
		<img src={ro} alt="ro" />
		<span>RO</span>
	</span>
);

const RuLng = () => (
	<span className="topbar__language-btn-title">
		<img src={ru} alt="ru" />
		<span>RU</span>
	</span>
);

const RsLng = () => (
	<span className="topbar__language-btn-title">
		<img src={rs} alt="rs" />
		<span>SR-SP</span>
	</span>
);

const SkLng = () => (
	<span className="topbar__language-btn-title">
		<img src={sk} alt="sk" />
		<span>sk-SK</span>
	</span>
);

const SiLng = () => (
	<span className="topbar__language-btn-title">
		<img src={si} alt="si" />
		<span>sl-SI</span>
	</span>
);

const SeLng = () => (
	<span className="topbar__language-btn-title">
		<img src={se} alt="se" />
		<span>sv-SE</span>
	</span>
);

const ThLng = () => (
	<span className="topbar__language-btn-title">
		<img src={th} alt="th" />
		<span>th-TH</span>
	</span>
);

const TrLng = () => (
	<span className="topbar__language-btn-title">
		<img src={tr} alt="tr" />
		<span>tr-TR</span>
	</span>
);

class Topbar extends PureComponent {
	static propTypes = {
		i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			inputOpen: false,
			collapseMail: false,
			collapseProfile: false,
			collapseLng: false,
			mainButtonContent: <EnLng />,
			user_pic: null,
			projectid: null,
			anchorEl: null,
			collapseProject: false,
			projectName: null,
			languageCodeArray: [],
			isOpenRealTimeLogoutModal: false,
			lic_exp_dtl: {},
			plan_exp_dtl: {},
			loading: false,
			licenceExpDisplayOnce: true,
			isOpenLicenceExpModal: false,
			warning: false,
			notification: {
				success: true,
				message: null,
			},
			isOpenLicenceExpModalWithInDay: false,
			renewLicenceModal: false,
			renewMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			selRenewLicDet: {},
			error: [],
			requestPlanModal: false,
			selectedMonth: 1,
			isOpenPlanExpModalWithInDay: false,
			allCmpName: [],
			allCmpDisName: [],
			modalSwitchCompany: false,
			projectList: [],
			projectListTemp: [],
			searchTxt: "",
			logoutUserConfirmation: false,
		};
	}

	componentDidMount() {
		// if(!(localStorage.getItem("language_codes"))){
		this.props.getLanguageCodeMasterList(this.props.apolloClient.masters, []);
		// }
		//setInterval(() => {
		let request = {
			email: this.props.authReducer.userData.email,
			projectid: this.props.authReducer.userData.projectid,
		};
		this.props.getAccess(this.props.apolloClient.client, request);
		//},20000);

		//My code to fetch and set all companies of user
		const allCmpName = JSON.parse(this.props.authReducer.userData.all_company_name);
		const allCmpDisName = JSON.parse(this.props.authReducer.userData.all_display_name);
		if (allCmpName) {
			this.setState({
				allCmpName,
				allCmpDisName
			})
		}

	}

	componentWillMount() {
		if (!this.props.authReducer.userData.staffuser) {
			this.setState({
				projectid: this.props.authReducer.userData.projectid,
			});

			// let request = {
			//   companyname: this.props.authReducer.userData.company_name
			// }
			// this.props.getUserProjectList(this.props.apolloClient.project, request);

			//I comment this above API and use this below API to solve the issue of Active Project name at header is not display in Invite User Login
			let request = {
				companyname: this.props.authReducer.userData.company_name,
				userid: this.props.authReducer.userData.userid,
			};
			this.props.getCompanyProjectList(this.props.apolloClient.client, request);
		}

		let languagaeData = localStorage.getItem('language');
		this.changeLanguageComponent(languagaeData);
		let profile = null;
		if (this.props.authReducer.userData.userattachments) {
			profile = JSON.parse(this.props.authReducer.userData.userattachments);
			if (profile.length > 0) {
				profile = profile[0].user_pic;
			}
		}

		this.setState({
			user_pic: profile && profile.length > 0 ? profile : null,
		});
	}

	componentWillReceiveProps(props) {
		if (props.authReducer.userData) {
			//if(props.authReducer.userData.account_status == false || props.authReducer.userData.islicenseexp == true || props.authReducer.userData.isplanexp == true){
			if (props.authReducer.userData.account_status == false) {
				this.setState({
					isOpenRealTimeLogoutModal: true,
				});
			}

			//start licence expired warning code - open popup before 2 days license expired

			let lic_expirydate = props.authReducer.userData.lic_expirydate;
			let plan_expirydate = props.authReducer.userData.plan_expirydate;

			let current_date = Math.floor(new Date().getTime() / 1000.0); // 1-10-2021
			let dateOffset = 24 * 60 * 60 * 2; //2 days deduct; //

			let checkLicExpDate = lic_expirydate - dateOffset;

			let licPopup = 'close';
			let licence_expiry_alert = localStorage.getItem(
				'renew_licence_expiry_alert_' + this.props.authReducer.userData.projectid
			);

			if (
				this.props.authReducer.userData.company_name != '["superadmin"]' &&
				current_date >= checkLicExpDate &&
				lic_expirydate >= current_date &&
				licence_expiry_alert == null
			) {
				licPopup = 'open';
				let lic_exp_dtl = props.authReducer.userData.lic_exp_dtl;
				this.setState({
					isOpenLicenceExpModalWithInDay: true,
					lic_exp_dtl: typeof lic_exp_dtl != 'undefined' ? lic_exp_dtl : '',
				});
			}
			// end code

			// start plan expired warning code - open popup before 2 days plan expired

			let checkPlanExpDate = plan_expirydate - dateOffset;
			let planPopup = 'close';
			let plan_expiry_alert = localStorage.getItem(
				'renew_plan_expiry_alert_' + this.props.authReducer.userData.projectid
			);

			if (
				this.props.authReducer.userData.company_name != '["superadmin"]' &&
				current_date >= checkPlanExpDate &&
				plan_expirydate >= current_date &&
				plan_expiry_alert == null
			) {
				planPopup = 'open';
				let plan_exp_dtl = props.authReducer.userData.plan;
				this.setState({
					isOpenPlanExpModalWithInDay: true,
					plan_exp_dtl: typeof plan_exp_dtl != 'undefined' ? plan_exp_dtl : '',
				});
			}

			// end code

			if (
				props.authReducer.userData.islicenseexp == true &&
				this.state.licenceExpDisplayOnce == true &&
				localStorage.getItem('licence_' + this.props.authReducer.userData.projectid) == null &&
				props.authReducer.userData.owner == props.authReducer.userData.email
			) {
				let lic_exp_dtl = props.authReducer.userData.lic_exp_dtl;
				if (typeof lic_exp_dtl != 'undefined') {
					this.setState(
						{ lic_exp_dtl: lic_exp_dtl, isOpenLicenceExpModal: true },
						this.forceUpdate()
					);
				}
			}

			if (
				props.authReducer.userData.islicenseexp == true &&
				this.state.licenceExpDisplayOnce == true &&
				localStorage.getItem('licence_' + this.props.authReducer.userData.projectid) == null &&
				props.authReducer.userData.owner != props.authReducer.userData.email
			) {
				let lic_exp_dtl = props.authReducer.userData.lic_exp_dtl;

				if (typeof lic_exp_dtl != 'undefined') {
					this.setState(
						{ lic_exp_dtl: lic_exp_dtl, isOpenLicenceExpModal: true },
						this.forceUpdate()
					);
				}
			}
			if (props.authReducer.userData.islicenseexp == false) {
				if (localStorage.getItem('licence_' + this.props.authReducer.userData.projectid) != null) {
					localStorage.removeItem('licence_' + this.props.authReducer.userData.projectid);
				}
			}
		}
		if (props.authReducer.companyList && props.authReducer.companyList.length > 0) {
			let projectname = props.authReducer.companyList.filter(
				val => val.projectid === this.props.authReducer.userData.projectid
			);

			let projectList = props.authReducer.companyList;
			projectList.sort(function compare(a, b) {
				var dateA = new Date(a.startDate);
				var dateB = new Date(b.startDate);
				return dateB - dateA;
			});

			//display recently used 5 projects at the top of the list
			if (props.authReducer.userData.lastVisitedProjects) {
				const getCmpProjects = props.authReducer.userData.lastVisitedProjects.find(l => l.email === props.authReducer.userData.email && l.companyName === props.authReducer.userData.company_name)
				if (getCmpProjects) {
					const filter1 = [];
					const getProjects = getCmpProjects.projects;
					getProjects.map((p) => {
						const fltPrj = projectList.find(l => l.projectid === p);
						if (fltPrj) {
							filter1.push(fltPrj);
						}
					})
					filter1.reverse();
					const filter2 = projectList.filter(l => !getProjects.includes(l.projectid));
					projectList = [...filter1, ...filter2];
				}
			}

			if (projectname.length > 0) {
				this.setState({
					projectName: projectname[0].projectname,
					projectList: projectList,
					projectListTemp: projectList,
				});
			} else {
				this.setState({
					projectName: null,
					projectList: projectList,
					projectListTemp: projectList,

				});
			}
		}
		let languagecodeLabelList = props.languagecodeReducer.languagecodeLabelList;
		if (languagecodeLabelList) {
			this.setState(
				{
					languageCodeArray: languagecodeLabelList,
				},
				() => this.forceUpdate()
			);
		}
	}

	changeLanguageComponent = lng => {
		const { i18n } = this.props;
		i18n.changeLanguage(lng);
		switch (lng) {
			case 'en':
				this.setState({ mainButtonContent: <EnLng /> });
				break;
			case 'fr':
				this.setState({ mainButtonContent: <FrLng /> });
				break;
			case 'de':
				this.setState({ mainButtonContent: <DeLng /> });
				break;
			case 'it':
				this.setState({ mainButtonContent: <ItLng /> });
				break;
			case 'gb':
				this.setState({ mainButtonContent: <GbukLng /> });
				break;
			case 'es':
				this.setState({ mainButtonContent: <EsLng /> });
				break;
			case 'af':
				this.setState({ mainButtonContent: <AfLng /> });
				break;
			case 'at':
				this.setState({ mainButtonContent: <AtLng /> });
				break;
			case 'be':
				this.setState({ mainButtonContent: <BeLng /> });
				break;
			case 'br':
				this.setState({ mainButtonContent: <BrLng /> });
				break;
			case 'bg':
				this.setState({ mainButtonContent: <BgLng /> });
				break;
			case 'hr':
				this.setState({ mainButtonContent: <HrLng /> });
				break;
			case 'cz':
				this.setState({ mainButtonContent: <CzLng /> });
				break;
			case 'dk':
				this.setState({ mainButtonContent: <DkLng /> });
				break;
			case 'et':
				this.setState({ mainButtonContent: <EtLng /> });
				break;
			case 'fi':
				this.setState({ mainButtonContent: <FiLng /> });
				break;
			case 'ge':
				this.setState({ mainButtonContent: <GeLng /> });
				break;
			case 'gr':
				this.setState({ mainButtonContent: <GrLng /> });
				break;
			case 'hu':
				this.setState({ mainButtonContent: <HuLng /> });
				break;
			case 'in':
				this.setState({ mainButtonContent: <InLng /> });
				break;
			case 'lv':
				this.setState({ mainButtonContent: <LvLng /> });
				break;
			case 'lt':
				this.setState({ mainButtonContent: <LtLng /> });
				break;
			case 'nl':
				this.setState({ mainButtonContent: <NlLng /> });
				break;
			case 'no':
				this.setState({ mainButtonContent: <NoLng /> });
				break;
			case 'pl':
				this.setState({ mainButtonContent: <PlLng /> });
				break;
			case 'pt':
				this.setState({ mainButtonContent: <PtLng /> });
				break;
			case 'ro':
				this.setState({ mainButtonContent: <RoLng /> });
				break;
			case 'ru':
				this.setState({ mainButtonContent: <RuLng /> });
				break;
			case 'rs':
				this.setState({ mainButtonContent: <RsLng /> });
				break;
			case 'sk':
				this.setState({ mainButtonContent: <SkLng /> });
				break;
			case 'si':
				this.setState({ mainButtonContent: <SiLng /> });
				break;
			case 'se':
				this.setState({ mainButtonContent: <SeLng /> });
				break;
			case 'th':
				this.setState({ mainButtonContent: <ThLng /> });
				break;
			case 'tr':
				this.setState({ mainButtonContent: <TrLng /> });
				break;
			default:
				this.setState({ mainButtonContent: <EnLng /> });
				break;
		}
	};

	changeLanguage = lng => {
		let currentLanguage = localStorage.getItem('language');
		if (currentLanguage != '' && currentLanguage != lng) {
			const { i18n } = this.props;
			localStorage.setItem('language', lng ? lng : 'en');
			let request = {
				isreload: true,
			};
			this.props.getLanguageMasterListFront(this.props.apolloClient.language, request);

			//Save the user preffered language for all project related emails sent in this user preferred language
			let requestParams = {
				lang_code: lng,
				userid: this.props.authReducer.userData.userid,
			};
			this.props.changeUserLangPref(this.props.apolloClient.client, requestParams);

			i18n.changeLanguage(lng);
			switch (lng) {
				case 'en':
					this.setState({ mainButtonContent: <EnLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'fr':
					this.setState({ mainButtonContent: <FrLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'de':
					this.setState({ mainButtonContent: <DeLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'it':
					this.setState({ mainButtonContent: <ItLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'gb':
					this.setState({ mainButtonContent: <GbukLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'es':
					this.setState({ mainButtonContent: <EsLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'af':
					this.setState({ mainButtonContent: <AfLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'at':
					this.setState({ mainButtonContent: <AtLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'be':
					this.setState({ mainButtonContent: <BeLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'br':
					this.setState({ mainButtonContent: <BrLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'bg':
					this.setState({ mainButtonContent: <BgLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'hr':
					this.setState({ mainButtonContent: <HrLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'cz':
					this.setState({ mainButtonContent: <CzLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'dk':
					this.setState({ mainButtonContent: <DkLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'et':
					this.setState({ mainButtonContent: <EtLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'fi':
					this.setState({ mainButtonContent: <FiLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'ge':
					this.setState({ mainButtonContent: <GeLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'gr':
					this.setState({ mainButtonContent: <GrLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'hu':
					this.setState({ mainButtonContent: <HuLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'in':
					this.setState({ mainButtonContent: <InLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'lv':
					this.setState({ mainButtonContent: <LvLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'lt':
					this.setState({ mainButtonContent: <LtLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'nl':
					this.setState({ mainButtonContent: <NlLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'no':
					this.setState({ mainButtonContent: <NoLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'pl':
					this.setState({ mainButtonContent: <PlLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'pt':
					this.setState({ mainButtonContent: <PtLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'ro':
					this.setState({ mainButtonContent: <RoLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'ru':
					this.setState({ mainButtonContent: <RuLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'rs':
					this.setState({ mainButtonContent: <RsLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'sk':
					this.setState({ mainButtonContent: <SkLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'si':
					this.setState({ mainButtonContent: <SiLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'se':
					this.setState({ mainButtonContent: <SeLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'th':
					this.setState({ mainButtonContent: <ThLng />, collapseLng: !this.state.collapseLng });
					break;
				case 'tr':
					this.setState({ mainButtonContent: <TrLng />, collapseLng: !this.state.collapseLng });
					break;
				default:
					this.setState({ mainButtonContent: <EnLng />, collapseLng: !this.state.collapseLng });
					break;
			}
		} else {
			this.toggleLng();
		}
	};

	toggleProject = () => {
		this.setState({
			searchTxt: "",
			projectList: [...this.state.projectListTemp],
			collapseProject: !this.state.collapseProject,
		});
	};

	logoutUser = () => {

		//Update Logout time to database
		let request = {
			email: this.props.authReducer.userData.email,
		};
		this.props.logout(this.props.apolloClient.client, request);

		// localStorage.clear();
		localStorage.removeItem('_user_');
		localStorage.removeItem('language_data');
		localStorage.removeItem('language_codes');
		//localStorage.removeItem("language");
		localStorage.removeItem('_userDetail_');
		localStorage.removeItem('client_request_tab');
		localStorage.removeItem(
			'renew_licence_expiry_alert_' + this.props.authReducer.userData.projectid
		);
		localStorage.removeItem('renew_plan_expiry_alert_' + this.props.authReducer.userData.projectid);
		window.location = '/signin';
	};

	// close expiry licence modal
	closeLicenceExpModalWithInDay = () => {
		localStorage.setItem(
			'renew_licence_expiry_alert_' + this.props.authReducer.userData.projectid,
			'renew_licence_expiry_alert_' + this.props.authReducer.userData.projectid
		);
		this.setState({
			isOpenLicenceExpModalWithInDay: false,
		});
	};

	// close expiry plan modal
	closePlanExpModalWithInDay = () => {
		localStorage.setItem(
			'renew_plan_expiry_alert_' + this.props.authReducer.userData.projectid,
			'renew_plan_expiry_alert_' + this.props.authReducer.userData.projectid
		);
		this.setState({
			isOpenPlanExpModalWithInDay: false,
		});
	};

	onInputOpen = e => {
		e.preventDefault();
		this.setState({ inputOpen: !this.state.inputOpen });
	};

	toggleLng = () => {
		this.setState({ collapseLng: !this.state.collapseLng });
	};

	toggleProfile = () => {
		this.setState({ collapseProfile: !this.state.collapseProfile });
	};

	toggleMail = () => {
		this.setState({ collapseMail: !this.state.collapseMail });
	};

	changeProject = (projectId, projectName, toggleProject = false) => {
		if (projectId != this.state.projectid) {
			this.setState(
				{
					projectid: projectId,
					projectName: projectName,
				},
				() => {
					if (!toggleProject) {
						this.toggleProject();
					}
					this.forceUpdate();
				}
			);
			let request = {
				email: this.props.authReducer.userData.email,
				projectid: projectId,
				isRefresh: true,
			};
			this.props.getAccess(this.props.apolloClient.client, request);
		} else {
			this.toggleProject();
		}
	};

	handleClick = event => {
		this.setState({
			anchorEl: event.currentTarget,
		});
	};

	handleClose = () => {
		this.setState({
			anchorEl: null,
		});
	};

	//toggle notifications
	toggleWarning = () => {
		this.setState({ warning: !this.state.warning });
	};

	closeLicenceExpModal = () => {
		this.setState({
			//isOpenLicenceExpModal: !this.state.isOpenLicenceExpModal,
			isOpenLicenceExpModal: false,
			licenceExpDisplayOnce: false,
			loading: false,
		});
	};

	closeRenewLicence = () => {
		this.setState({
			renewLicenceModal: !this.state.renewLicenceModal,
			selRenewLicDet: {},
		});
	};

	renewActiveLicence = () => {
		localStorage.setItem('licence_' + this.props.authReducer.userData.projectid, 'requestforrenew');

		let value = this.state.selRenewLicDet;

		var licences = [
			{
				licenceid: value.licenceid,
				companylicenceid: value.companylicenceid,
				licencename: value.licencename,
				price: typeof value.cost !== 'undefined' ? value.cost : value.price,
				//price: value.totalCost,
				month: value.selMonth,
				count: 1,
				companyname: this.props.authReducer.userDetail.company_name, //Pass the user original company name
				displayname: this.props.authReducer.userDetail.display_name, //Pass the user original company display name
			},
		];

		let req = {
			licences: JSON.stringify(licences),
			company_id: this.props.authReducer.userData.company_id,
		};

		this.props.requestLicenceForRenew(
			this.props.apolloClient.masters,
			req,
			this.props.apolloClient.client
		);
		this.setState({ loading: true });

		setTimeout(
			function () {
				this.closeLicenceExpModal();
				this.closeLicenceExpModalWithInDay();
				this.closeRenewLicence();
			}.bind(this),
			3000
		);
	};

	applyRenewLicence = () => {
		localStorage.setItem('licence_' + this.props.authReducer.userData.projectid, 'requestforrenew');

		var licences = [
			{
				licenceid: this.state.lic_exp_dtl.licenceid,
				companylicenceid: this.state.lic_exp_dtl.companylicenceid,
				licencename: this.state.lic_exp_dtl.licencename,
				price: this.state.lic_exp_dtl.cost,
				count: 1,
				// companyname: this.props.authReducer.userData.company_name,
				// displayname: this.props.authReducer.userData.display_name
				companyname: this.props.authReducer.userDetail.company_name,
				displayname: this.props.authReducer.userDetail.display_name,
			},
		];

		let req = {
			licences: JSON.stringify(licences),
			company_id: this.props.authReducer.userData.company_id,
		};

		this.props.requestLicenceForRenew(
			this.props.apolloClient.masters,
			req,
			this.props.apolloClient.client
		);
		this.setState({ loading: true });

		setTimeout(
			function () {
				this.closeLicenceExpModal();
				this.closeLicenceExpModalWithInDay();
			}.bind(this),
			3000
		);
	};

	toggleProfile1 = () => {
		this.props.history.push('/');
		this.changeProject(null, null, true);
		this.setState({ collapseProfile: !this.state.collapseProfile, collapseProject: false });
	};

	toggleRenewLicenceModal = () => {
		let value = this.state.lic_exp_dtl;
		if (value) {
			value.selMonth = 1;
			value.totalCost = typeof value.cost != 'undefined' ? value.cost : value.price;
			this.setState({
				renewLicenceModal: !this.state.renewLicenceModal,
				error: [],
				selRenewLicDet: value,
			});
		}
	};

	handleRenewLicence = name => event => {
		let cost =
			typeof this.state.selRenewLicDet.cost !== 'undefined'
				? this.state.selRenewLicDet.cost
				: this.state.selRenewLicDet.price;
		let totalCost = cost * event.target.value;

		this.setState({
			selRenewLicDet: {
				...this.state.selRenewLicDet,
				[name]: event.target.value,
				totalCost: totalCost,
			},
		});
	};

	/* store the plan index */
	choosePlan = plan => {
		let planData = this.props.masterReducer.planList[plan];
		this.setState({
			plan: plan,
			memberSize: planData ? planData.usersize : 0,
			inviteList: [],
			selectedMonth: 1,
		});
	};

	renewPlan = () => {
		let plan = this.state.plan_exp_dtl;
		let allPlanList = this.props.masterReducer.planList;
		let currentPlanIndex = allPlanList.findIndex((l) => l.planid == plan.planid);
		this.setState({
			requestPlanModal: !this.state.requestPlanModal
		});
		this.choosePlan(currentPlanIndex);
	}

	handleChangeInfo = (keyname, event) => {
		if (keyname === "selectedMonth") {
			this.setState({ selectedMonth: event.target.value })
		}
	}

	/* toggle request plan */
	toggleRequestPlan = () => {
		this.setState({
			requestPlanModal: !this.state.requestPlanModal,
			plan: null,
			selectedMonth: 1,
		});
	};

	toggleSwitchCompany = () => {
		//Run Get User data api for getting latest invited company info
		let request = {
			email: this.props.authReducer.userData.email,
			projectid: this.props.authReducer.userData.projectid,
		};
		this.props.getAccess(this.props.apolloClient.client, request);
		this.setState({ modalSwitchCompany: true });
	}

	// submit form purchase plan
	requestPlan = () => {
		this.setState({
			requestPlanModal: !this.state.requestPlanModal
		});
		let plan = this.props.masterReducer.planList[this.state.plan];

		let requestParams = {
			plan_id: plan.planid,
			plan_name: plan.planname,
			price: plan.price,
			month: this.state.selectedMonth,
			company_name: this.props.authReducer.userData.company_name,
			display_name: this.props.authReducer.userData.display_name,
			company_id: this.props.authReducer.userData.company_id
		};

		/* get request Plan */
		this.props.requestPlan(this.props.apolloClient.masters, requestParams, this.props.apolloClient.client);
		this.setState({
			loading: true
		});
		setTimeout(
			function () {
				this.closePlanExpModalWithInDay();
			}.bind(this),
			3000
		);
	};

	handleSearch = (e) => {
		const srcVal = e.target.value;
		this.setState({ searchTxt: srcVal }, function () {
			let tempProjectList = [...this.state.projectListTemp];
			tempProjectList = tempProjectList.filter(l => {
				if (l.projectname.toLowerCase().includes(srcVal.toLowerCase())) {
					return true;
				}
				return false;
			});
			this.setState({ projectList: tempProjectList })
		});
	}

	render() {
		let languageCodes = this.props.languagecodeReducer.languagecodeLabelList;
		let currentLanguage = localStorage.getItem('language');
		const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;
		const {
			collapseProject,
			collapseMail,
			collapseProfile,
			collapseLng,
			mainButtonContent,
		} = this.state;
		const { t } = this.props;

		const links = [
			{
				title: topbar_link_profile,
				icon: 'user',
				path: this.props.authReducer.userData.staffuser ? '/account/profile' : '/client/profile',
			},
		];


		// if (this.props.authReducer.companyList) {
		// 	this.props.authReducer.companyList.sort(function compare(a, b) {
		// 		var dateA = new Date(a.startDate);
		// 		var dateB = new Date(b.startDate);
		// 		return dateB - dateA;
		// 	});
		// }

		//console.log("userData = ", this.props.authReducer.userData);
		const firstLetter = this.props.authReducer.userData.name.charAt(0).toLowerCase();
		const isCreateProject = ((this.props.authReducer.userData.owner === this.props.authReducer.userData.email) || (this.props.authReducer.userData?.role_id === "AXD_RL_15"));
		const path = window.location.pathname;
		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left">
						<Link className="topbar__logo" to="/" />
					</div>
					<div className="topbar__center" style={{ margin: 'auto', width: '62%' }}>
						{!this.props.authReducer.userData.staffuser && (
							<div style={{ marginLeft: '0px' }} className="topbar__profile">
								<div
									className="active_project"
									style={{
										borderRight: '1px solid #E64823',
										paddingRight: '10px',
										marginRight: '10px',
									}}
								>
									<span
										className="font-weight-500"
										style={{ fontWeight: 600, fontSize: '16px', color: '#e64823' }}
									>
										{active_company_name} :{' '}
									</span>

									<Tooltip title={this.props.authReducer.userData.display_name}>
										<span style={{ fontWeight: 400, fontSize: '16px', color: '#e64823' }}>
											{this.props.authReducer.userData.display_name.substring(0, 14)}{' '}
											{this.props.authReducer.userData.display_name.length > 14 ? '...' : ''}
										</span>
									</Tooltip>
									{this.state.allCmpName.length > 1 &&
										<Button
											title={""}
											color="success"
											onClick={() => this.toggleSwitchCompany()}
											className={'system-btn clear-border'}
											outline={true}
										>
											<Tooltip title={change_company}>
												<Icon>sync_alt</Icon>
											</Tooltip>
										</Button>
									}
								</div>

								<div className="active_project" style={{ marginRight: '17px' }}>
									<span
										className="font-weight-500"
										style={{ fontWeight: 600, fontSize: '16px', color: '#e64823' }}
									>
										{active_project_text} :{' '}
									</span>
									<Tooltip
										title={
											this.state.projectName &&
												this.props.authReducer.companyList &&
												this.props.authReducer.companyList.length != 0
												? this.state.projectName
												: '-'
										}
									>
										<span style={{ fontWeight: 400, fontSize: '16px', color: '#e64823' }}>
											{this.state.projectName &&
												this.props.authReducer.companyList &&
												this.props.authReducer.companyList.length != 0
												? this.state.projectName.substring(0, 14)
												: '-'}
											{this.state.projectName &&
												this.props.authReducer.companyList &&
												this.props.authReducer.companyList.length != 0 &&
												this.state.projectName.length > 14
												? '...'
												: ''}
										</span>
									</Tooltip>
								</div>
								<Button
									style={{ padding: '10px 13px' }}
									className="active_project_btn"
									color="success"
									outline={true}
									type="button"
									onClick={this.toggleProject}
								>
									{this.props.authReducer.userData?.projectid ? change_project_text : label_select_project}
									<DownIcon className="topbar__icon" />
								</Button>

								{collapseProject && (
									<button className="topbar__back" type="button" onClick={this.toggleProject} />
								)}
								<Collapse
									style={{ width: '45%', margin: "0 22vw", paddingTop: "1%", paddingBottom: "1%" }}
									isOpen={collapseProject}
									className="topbar__menu-wrap topbar__menu"
								>
									{isCreateProject &&
										<Link
											key={Math.floor(Math.random() * 10000000)}
											onClick={this.toggleProject}
											className="topbar__link"
											to="/client/newProject"
										>
											<p style={{ color: '#E64823' }} className="topbar__link-title">
												{clientmenu_add_project}
											</p>
											<span
												style={{ color: '#E64823', marginLeft: '4%' }}
												className={`topbar__link-icon lnr lnr-plus-circle`}
											/>
										</Link>
									}
									{this.props.authReducer.companyList?.length > 0 &&
										<Input
											onChange={e => this.handleSearch(e)}
											type="text"
											value={this.state.searchTxt}
											placeholder={search_text + "..."}
											style={SrcInpStyl}
										/>
									}
									<div className="topbar__project__list">

										{this.state.projectList.length > 0 &&
											this.state.projectList.map((option, index) => {
												return (
													<>
														<div
															style={{
																backgroundColor: option.projectid == this.state.projectid ? '#ededed' : '',
															}}
															key={index}
															className="topbar__link"
															onClick={() => this.changeProject(option.projectid, option.projectname)}
														>
															<p className="topbar__link-title" style={{ color: option.isclosed ? '#dd3333' : '' }}>{option.projectname}</p>
														</div>
														{index === 5 && this.state.projectList.length > 6 && (
															<div key={'lineBetween'} className="topbar__link">
																<p className="topbar__link-title" style={{ color: 'var(--clr-aexdo)' }}>
																	{'--------------------------------------------------------------------------------'}
																</p>
															</div>
														)}
													</>
												);
											})}
									</div>
								</Collapse>
								<Link
									key={Math.floor(Math.random() * 10000000)}
									className=""
									to="/client/projectList"
								>
									<Button
										style={{ padding: '10px 20px' }}
										className="active_project_btn"
										color="success"
										outline={true}
										type="button"
									>
										{clientmenu_view_project}
									</Button>
								</Link>
							</div>
						)}
					</div>

					<div className="topbar__right">
						<div className="topbar__collapse">
							<HeaderNotification />
						</div>

						<div style={{ marginLeft: '0px' }} className="topbar__profile">
							<button
								style={{ padding: '0 10px', paddingLeft: '5px' }}
								className="topbar__avatar"
								type="button"
								onClick={this.toggleProfile}
							>
								<img
									className="topbar__avatar-img"
									src={
										this.state.user_pic
											? this.state.user_pic
											: `${process.env.PUBLIC_URL}/img/topbar/letter-${firstLetter}.png`
									}
									alt="avatar"
								/>
								<p style={{ marginLeft: '5px' }} className="topbar__avatar-name">
									{this.props.authReducer.userData.name.length <= 15
										? this.props.authReducer.userData.name
										: this.props.authReducer.userData.name.substring(0, 15) + '...'}
								</p>
								<DownIcon style={{ marginLeft: '0px' }} className="topbar__icon" />
							</button>
							{collapseProfile && (
								<button className="topbar__back" type="button" onClick={this.toggleProfile} />
							)}
							<Collapse
								isOpen={collapseProfile}
								className="topbar__menu-wrap topbar__menu-wrap-profile"
							>
								<div className="topbar__menu topbar__menu__profile">
									{links.map((row, index) => {
										return (
											<Link
												style={{
													backgroundColor: path == row.path ? '#ededed' : '',
												}}
												key={index}
												className={`topbar__link ${path == row.path ? 'topbar__link-active' : ''}`}
												to={row.path}
												onClick={this.toggleProfile}
											>
												<span className={`topbar__link-icon lnr lnr-${row.icon}`} />
												<p className="topbar__link-title">{row.title}</p>
											</Link>
										);
									})}

									{(this.props.authReducer.userData.company_name == '["superadmin"]' ||
										this.props.authReducer.userData.email ==
										this.props.authReducer.userData.owner) && (
											<>
												<Link
													style={{
														backgroundColor:
															path == '/client/invoices' ||
																path == '/admin/invoices'
																? '#ededed'
																: '',
													}}
													key={Math.floor(Math.random() * 10000000)}
													className={`topbar__link ${path == '/client/invoices' ||
													path == '/admin/invoices' ? 'topbar__link-active' : ''}`}
													to={
														this.props.authReducer.userData.company_name == '["superadmin"]'
															? '/admin/invoices'
															: '/client/invoices'
													}
													onClick={this.toggleProfile}
												>
													<span className={`topbar__link-icon lnr lnr-book`} />
													<p className="topbar__link-title">{topbar_link_invoices}</p>
												</Link>

												<Link
													style={{
														backgroundColor:
															path == '/client/systemLog' ||
																path == '/admin/systemLog'
																? '#ededed'
																: '',
													}}
													key={Math.floor(Math.random() * 10000000)}
													className={`topbar__link ${path == '/client/systemLog' ||
													path == '/admin/systemLog' ? 'topbar__link-active' : ''}`}
													to={
														this.props.authReducer.userData.company_name == '["superadmin"]'
															? '/admin/systemLog'
															: '/client/systemLog'
													}
													onClick={this.toggleProfile}
												>
													<span className={`topbar__link-icon lnr lnr-database`} />
													<p className="topbar__link-title">{topbar_link_system_log}</p>
												</Link>
											</>
										)}

									{this.props.authReducer.userData.company_name != '["superadmin"]' &&
										this.props.authReducer.userData.email ==
										this.props.authReducer.userData.owner && (
											<a
												href="javascript:void(0)"
												style={{
													backgroundColor: path == '/' ? '#ededed' : '',
												}}
												className={`topbar__link ${path == '/' ? 'topbar__link-active' : ''}`}
												onClick={this.toggleProfile1}
											>
												<span className={`topbar__link-icon lnr lnr-home`} />
												<p className="topbar__link-title">{company_statistics}</p>
											</a>
										)}

									{this.props.authReducer.userData.company_name != '["superadmin"]' && (
										<>
											<Link
												style={{
													backgroundColor:
														path == '/client/settings'
															? '#ededed'
															: '',
												}}
												key={Math.floor(Math.random() * 10000000)}
												className={`topbar__link ${path == '/client/settings' ? 'topbar__link-active' : ''}`}
												to="/client/settings"
												onClick={this.toggleProfile}
											>
												<span className={`topbar__link-icon lnr lnr-cog`} />
												<p className="topbar__link-title">{"Settings"}</p>
											</Link>

											{/* <Link
												style={{
													backgroundColor:
														path == '/client/notificationSetting'
															? '#ededed'
															: '',
												}}
												key={Math.floor(Math.random() * 10000000)}
												className={`topbar__link ${path == '/client/notificationSetting' ? 'topbar__link-active' : ''}`}
												to="/client/notificationSetting"
												onClick={this.toggleProfile}
											>
												<span className={`topbar__link-icon lnr lnr-cog`} />
												<p className="topbar__link-title">{topbar_link_notification_setting}</p>
											</Link>

											<Link
												style={{
													backgroundColor:
														path == '/client/emailSetting' ? '#ededed' : '',
												}}
												key={Math.floor(Math.random() * 10000000)}
												className={`topbar__link ${path == '/client/emailSetting' ? 'topbar__link-active' : ''}`}
												to="/client/emailSetting"
												onClick={this.toggleProfile}
											>
												<span className={`topbar__link-icon lnr lnr-cog`} />
												<p className="topbar__link-title">{topbar_link_email_setting}</p>
											</Link> */}
										</>
									)}

									<div className="topbar__link" onClick={() => { this.setState({logoutUserConfirmation: true})} }>
										<span className={`topbar__link-icon lnr lnr-exit`} />
										<p className="topbar__link-title">{topbar_link_logout}</p>
									</div>
								</div>
							</Collapse>
						</div>

						<div className="topbar__collapse topbar__collapse--language">
							<button className="topbar__btn" type="button" onClick={this.toggleLng}>
								{mainButtonContent}
								<DownIcon className="topbar__icon" />
							</button>
							{collapseLng && (
								<button className="topbar__back" type="button" onClick={this.toggleLng} />
							)}
							<Collapse
								isOpen={collapseLng}
								className="topbar__collapse-content topbar__collapse-content--language"
							>
								{languageCodes.map((codevalue, index) => {
									let content;
									if (codevalue.code == 'fr') {
										content = <FrLng />;
									} else if (codevalue.code == 'en') {
										content = <EnLng />;
									} else if (codevalue.code == 'de') {
										content = <DeLng />;
									} else if (codevalue.code == 'it') {
										content = <ItLng />;
									} else if (codevalue.code == 'gb') {
										content = <GbukLng />;
									} else if (codevalue.code == 'es') {
										content = <EsLng />;
									} else if (codevalue.code == 'af') {
										content = <AfLng />;
									} else if (codevalue.code == 'at') {
										content = <AtLng />;
									} else if (codevalue.code == 'be') {
										content = <BeLng />;
									} else if (codevalue.code == 'br') {
										content = <BrLng />;
									} else if (codevalue.code == 'bg') {
										content = <BgLng />;
									} else if (codevalue.code == 'hr') {
										content = <HrLng />;
									} else if (codevalue.code == 'cz') {
										content = <CzLng />;
									} else if (codevalue.code == 'dk') {
										content = <DkLng />;
									} else if (codevalue.code == 'et') {
										content = <EtLng />;
									} else if (codevalue.code == 'fi') {
										content = <FiLng />;
									} else if (codevalue.code == 'ge') {
										content = <GeLng />;
									} else if (codevalue.code == 'gr') {
										content = <GrLng />;
									} else if (codevalue.code == 'hu') {
										content = <HuLng />;
									} else if (codevalue.code == 'in') {
										content = <InLng />;
									} else if (codevalue.code == 'lv') {
										content = <LvLng />;
									} else if (codevalue.code == 'lt') {
										content = <LtLng />;
									} else if (codevalue.code == 'nl') {
										content = <NlLng />;
									} else if (codevalue.code == 'no') {
										content = <NoLng />;
									} else if (codevalue.code == 'pl') {
										content = <PlLng />;
									} else if (codevalue.code == 'pt') {
										content = <PtLng />;
									} else if (codevalue.code == 'ro') {
										content = <RoLng />;
									} else if (codevalue.code == 'ru') {
										content = <RuLng />;
									} else if (codevalue.code == 'rs') {
										content = <RsLng />;
									} else if (codevalue.code == 'sk') {
										content = <SkLng />;
									} else if (codevalue.code == 'si') {
										content = <SiLng />;
									} else if (codevalue.code == 'se') {
										content = <SeLng />;
									} else if (codevalue.code == 'th') {
										content = <ThLng />;
									} else if (codevalue.code == 'tr') {
										content = <TrLng />;
									}

									if (codevalue.code != 'us') {
										if (codevalue.isvisible == true) {
											return (
												<button
													className="topbar__language-btn"
													type="button"
													style={{
														backgroundColor:
															codevalue.code == localStorage.getItem('language') ? '#ededed' : '',
													}}
													onClick={() => this.changeLanguage(codevalue.code)}
													key={codevalue.code}
												>
													{content}
												</button>
											);
										}
									}
								})}
							</Collapse>
						</div>
					</div>

					{this.state.isOpenLicenceExpModal && (
						<ModalLicenceExpiredWarning
							isOpen={this.state.isOpenLicenceExpModal}
							onClose={() => {
								this.closeLicenceExpModal()
							}}
							licence={this.state.lic_exp_dtl}
							onSave={() => this.toggleRenewLicenceModal()}
						/>
					)}

					{this.state.isOpenRealTimeLogoutModal && (
						<ModalBlockedUserRealTimeLogout
							isOpen={this.state.isOpenRealTimeLogoutModal}
							onClose={() => {
								this.logoutUser()
							}}
						/>
					)}

					{this.state.logoutUserConfirmation && (
						<ModalLogoutConfirmation
							isOpen={this.state.logoutUserConfirmation}
							onClose={() => {
								this.setState({logoutUserConfirmation: false})
							}}
							onSave={() => {
								this.logoutUser()
							}}
						/>
					)}

					{this.state.isOpenLicenceExpModalWithInDay && (
						<ModalLicenceExpirePreWarning
							isOpen={this.state.isOpenLicenceExpModalWithInDay}
							onClose={() => {
								this.closeLicenceExpModalWithInDay()
							}}
							licence={this.state.lic_exp_dtl}
							onSave={() => this.toggleRenewLicenceModal()}
						/>
					)}

					{this.state.isOpenPlanExpModalWithInDay && (
						<ModalPlanExpirePreWarning
							isOpen={this.state.isOpenPlanExpModalWithInDay}
							onClose={() => {
								this.closePlanExpModalWithInDay()
							}}
							plan={this.state.plan_exp_dtl}
							onSave={() => this.renewPlan()}
						/>
					)}

					{this.state.modalSwitchCompany && (
						<ModalSwitchCompany
							isOpen={this.state.modalSwitchCompany}
							onClose={() => this.setState({ modalSwitchCompany: false })}
						// initialData={{
						// 	allCmpName: this.state.allCmpName,
						// 	allCmpDisName: this.state.allCmpDisName,
						// }}
						// onSave={data => this.saveGroupFamily(data)}
						/>
					)}

					{this.state.warning && (
						<Snackbar
							autoHideDuration={5000}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							onClose={this.toggleWarning}
							open={this.state.warning}
							TransitionComponent={Fade}
							ContentProps={{
								style: this.state.notification.success
									? { background: 'green' }
									: { background: 'red' },
							}}
							message={this.state.notification.message}
							variant="success"
						/>
					)}

					{this.state.renewLicenceModal && Modals.renewLicenceModal(this)}
					{this.state.requestPlanModal && Modals.requestPlanModal(this)}
				</div>
			</div>
		);
	}
}

Topbar.propTypes = {
	t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		authReducer: state.authReducer,
		apolloClient: state.apolloClient,
		contentManagementReducer: state.contentManagementReducer,
		languagecodeReducer: state.languagecodeReducer,
		masterReducer: state.masterReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getCompanyProjectList: (client, request) => {
			dispatch(actions.getCompanyProjectList(client, request));
		},
		getAccess: (client, request) => {
			dispatch(actions.getAccess(client, request));
		},
		logout: (client, request) => {
			dispatch(actions.logout(client, request));
		},
		getUserProjectList: (client, request) => {
			dispatch(actions.getUserProjectList(client, request));
		},
		getUserNotificationList: (client, request) => {
			dispatch(actions.getUserNotificationList(client, request));
		},
		getUserNotificationUnreadCount: (client, request) => {
			dispatch(actions.getUserNotificationUnreadCount(client, request));
		},
		notificationMarkAllAsRead: (master, client, request) => {
			dispatch(actions.notificationMarkAllAsRead(master, client, request));
		},
		getLanguageCodeMasterList: (master, request) => {
			dispatch(actions.getLanguageCodeMasterList(master, request));
		},
		requestLicenceForRenew: (client, request, client2) => {
			dispatch(actions.requestLicenceForRenew(client, request, client2));
		},
		requestPlan: (client, request, client2) => {
			dispatch(actions.requestPlan(client, request, client2));
		},
		getLanguageMasterListFront: (master, request) => {
			dispatch(actions.getLanguageMasterListFront(master, request));
		},
		changeUserLangPref: (client, request) => {
			dispatch(actions.changeUserLangPref(client, request));
		},
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Topbar))
);
