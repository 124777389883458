import moment from 'moment-timezone';
import React, { Fragment, useState } from 'react';
import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Col,
	Modal,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	TabContent,
	TabPane,
	Input,
} from 'reactstrap';
import { Accordion, AccordionDetails, AccordionSummary, Icon, Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighchartsReact from 'highcharts-react-official';
import Lightbox from 'react-images';
import __ from 'underscore';
import * as actions from '../../../../Store/actions/index';
import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import formatDepth, { formatLngLat } from '../../../lib/formatDepth';
import useCreateGraphProbings from '../../../lib/useCreateGraphProbings';
import useSampleInfoForReport from '../../../lib/useSampleInfoForReport';
import { getMaterialListfilter } from '../../Common/materialList';
import * as ChangeAppLanguageFront from '../../Layout/ChangeAppLanguageFront';
import ModalHistory from '../Campaign/todoHistory';
import LegendItem from '../components/dataVisualization/legendItem';
import AnalysisReportsTable from '../components/reports/analysisReportsTable';
import TodoCheck from '../components/TodoCheck';
import Form from '../components/form';
import TwoLabelsSwitch from '../components/TwoLabelsSwitch';
import useLocalStorage from '../../../lib/useLocalStorage';
import MapButtonGroup from '../components/MapButtonGroup';
import Select2 from 'react-select';
import { formatGroupLabel } from '../../Common/commonFunctions';
import sortBy from 'lodash/sortBy';

moment.tz.setDefault('Europe/Paris');

let tlang = ChangeAppLanguageFront.translateLanguage;

const client_view_probing_details = tlang('client_view_probing_details') || 'View probing details';
const client_probing_code = tlang('client_probing_code') || 'Probing Code';
const label_probing_name = tlang('label_probing_name') || 'Probing Name';
const client_project_longtitude = tlang('client_project_longtitude') || 'Longitude';
const client_project_latitude = tlang('client_project_latitude') || 'Latitude';
const client_campaign_reference = tlang('client_campaign_reference') || 'Reference';
const client_probing_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_Moisture = tlang('client_Moisture') || 'Moisture';
const common_btn_okay = tlang('common_btn_okay') || 'Okay';
const material_masters_soil_family = tlang('materail_master_soil_family') || 'Soil Family';
const client_lithology_from = tlang('client_lithology_from') || 'From';
const client_lithology_to = tlang('client_lithology_to') || 'To';
const client_Inclutions = tlang('client_Inclutions') || 'Inclutions';
const client_Anomaly = tlang('client_Anomaly') || 'Anomaly';
const laboratory_txt = tlang('laboratory_txt') || 'Laboratory';
const client_Observation = tlang('client_Observation') || 'Observation';
const client_probing_inputtext = tlang('client_probing_inputtext') || 'Input text';
const client_probing_date = tlang('client_probing_date') || 'Probing Date';
const client_probing_lastname = tlang('client_probing_lastname') || 'Last Name';
const client_probing_Contact_Number = tlang('client_probing_Contact_Number') || 'Contact Number';
const client_probing_remarks = tlang('client_probing_remarks') || 'Remarks';
const client_probing_actual_depth = tlang('client_probing_actual_depth') || 'Actual Depth';
const client_probing_field_engineer = tlang('client_probing_field_engineer') || 'Field Engineer';
const client_probing_firstname = tlang('client_probing_firstname') || 'First Name';
const client_viewprobing_General_Details =
	tlang('client_viewprobing_General_Details') || 'General Details';
const client_sample_number = tlang('client_sample_number') || 'Sample Number';
const client_sample_name = tlang('client_sample_name') || 'Sample Name';
const client_Depth = tlang('client_Depth') || 'Depth';
const client_probing_depth_in_probing =
	tlang('client_probing_depth_in_probing') || 'Depth in Probing';
const client_Length = tlang('client_Length') || 'Length';
const client_probing_comment = tlang('client_probing_comment') || 'Comments';
const client_probing_audio_files = tlang('client_probing_audio_files') || 'Audio files';
const client_probing_sample_altitude = tlang('client_probing_sample_altitude') || 'Sample altitude';
const client_probing_remark = tlang('client_probing_remark') || 'Remark';
const client_probing_complete_layer = tlang('client_probing_complete_layer') || 'Complete layer';
const client_probing_expecteddepth = tlang('client_probing_expecteddepth') || 'Expected depth';
const client_probing_upper_depth = tlang('client_probing_upper_depth') || 'Upper Depth';
const client_probing_lower_depth = tlang('client_probing_lower_depth') || 'Lower Depth';
const client_probing_Layer_Length = tlang('client_probing_Layer_Length') || 'Layer Length';
const client_probing_Layer_Image = tlang('client_probing_Layer_Image') || 'Layer Image';
const client_Active = tlang('client_Active') || 'Active';
const client_campaign_color = tlang('client_campaign_color') || 'Color';
const client_probing_pid = tlang('client_probing_pid') || 'PID';
const mapbox_images = tlang('mapbox_images') || 'Images';
const client_project_altitude = tlang('client_project_altitude') || 'Altitude';
const client_disposal = tlang('client_disposal') || 'Disposal';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const label_layer_column_chart = tlang('label_layer_column_chart') || 'Layer column chart';
const client_no_analysis_found_for_probing =
	tlang('client_no_analysis_found_for_probing') || 'No Analysis found for this probing';
const client_view_analysis_report = tlang('client_view_analysis_report') || 'View Analysis Report';
const client_values_above_minimum_value_toggle =
	tlang('client_values_above_minimum_value_toggle') || 'Values Above Minimum Values';
const analysis_all_pollutants = tlang('analysis_all_pollutants') || 'All pollutants';
const client_Open_Probing = tlang('client_Open_Probing') || 'Open Probing';
const client_Close_Probing = tlang('client_Close_Probing') || 'Close Probing';
const todo_history = tlang('todo_history') || 'Todo history';
const client_btn_new_window = tlang('client_btn_new_window') || 'New Window';
const analysis_more_header_details = tlang('analysis_more_header_details') || 'More header Details';
const analysis_wider_columns = tlang('analysis_wider_columns') || 'Wider Columns';
const search_text = tlang('search_text') || 'Search';
const client_probing_category = tlang('client_probing_category') || 'Category';

export const ProbingInfoModal = function({ isOpen, onClose, probingIds, selectedId }) {
	const [openInWindow, setOpenInWindow] = useState(false);

	if (openInWindow)
		return (
			<RenderInNewWindow
				title={client_view_probing_details}
				onClose={() => {
					setOpenInWindow(false);
					onClose();
				}}
			>
				<Card>
					<CardBody>
						<ViewProbing probingIds={probingIds} selectedId={selectedId || probingIds[0]} />
					</CardBody>
				</Card>
			</RenderInNewWindow>
		);

	return (
		<Modal
			size="lg"
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
			style={{ maxWidth: '80%' }}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{client_view_probing_details}</h4>
			</div>
			<div style={{ maxHeight: 'calc(90vh - 54px - 31px)', overflowY: 'auto' }}>
				<Card>
					<CardBody>
						<ViewProbing probingIds={probingIds} selectedId={selectedId || probingIds[0]} />
					</CardBody>
				</Card>
			</div>
			<ButtonToolbar className="modal__footer">
				<Button color={'success'} outline={true} onClick={() => setOpenInWindow(true)}>
					{client_btn_new_window}
				</Button>
				<Button color={'success'} onClick={onClose}>
					{common_btn_okay}
				</Button>
			</ButtonToolbar>
		</Modal>
	);
};

const ViewProbing = function({ probingIds, selectedId }) {
	const dispatch = useDispatch();
	const apolloClient = useSelector(state => state.apolloClient);
	const authReducer = useSelector(state => state.authReducer);
	const masterReducer = useSelector(state => state.masterReducer);
	const probingReducer = useSelector(state => state.probingReducer);
	const [lightBoxUrl, setLightBoxUrl] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [probingIdx, setProbingIdx] = useState(-1);
	const [probing, setProbing] = useState({});
	const [probingImages, setProbingImages] = useState([]);
	const [probingAudios, setProbingAudios] = useState([]);
	const [layerImages, setLayerImages] = useState([]);
	const title = (probing.probingname || probing.probingcode) + ' ' + label_layer_column_chart;
	const [lists, setLists] = useState({});
	const [valGreToFirstDisThrshold, setvalGreToFirstDisThrshold] = useState(false);
	const [allPollutants, setAllPollutants] = useState(false);
	const [expand, setExpand] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	const [loading, setLoading] = useState(false);
	const [display, setDisplay] = useLocalStorage('probing-display', 'c');
	const [lockHeader, setLockHeader] = useState(false);
	const [widerColumns, setWiderColumns] = useState(true);
	const [findSearchValue, setFindSearchValue] = useState('');
	const [searchSample, setSearchSample] = useState(false);
	const searchValue = useRef(null);
	const [disposalCatList, setDisposalCatList] = useState([]);
	const [disposalCategory, setDisposalCategory] = useState({});
	const [selectedDefaultDisposalType, setSelectedDefaultDisposalType] = useState('');
	const [selectedDefaultDisposalList, setSelectedDefaultDisposalList] = useState('');
	const [disposals, setDisposals] = useState([]);
	const [allDisposalList, setAllDisposalList] = useState([]);

	const { reportData } = useSampleInfoForReport(
		[probing],
		[{ value: probing.campaignid, label: probing.campaignname }],
		disposals.length > 0 ? disposals : null, //For change report data according disposal category
		{ disposalOptions: { standardOnly: true } },
		{ allPollutants: allPollutants },
		{ valGreToFirstDisThrshold, expand, from: 'probinginfo', findSearchValue },
		{ list: selectedDefaultDisposalList, type: selectedDefaultDisposalType }
	);

	if (!__.isEmpty(reportData)) {
		reportData.lockHeader = lockHeader;
		reportData.widerColumns = widerColumns;
	}

	
	const {
		chartData,
		Highcharts,
		sampleInfo,
		loading: loadingGraph,
		
	} = useCreateGraphProbings(probing, title, 'layer', true, true,{
		standardOnly: true,
		allTypes: false,
		selectedDefaultDisposalType, //My code for generate probing graph based on selected disposal category in probing graph modal
		selectedDefaultDisposalList, //My code for generate probing graph based on selected disposal category in probing graph modal
	});
	
	const unit = authReducer.userData.default_unit || 'M';
	
	if (chartData.chart) chartData.chart.height = '1000px';

	useEffect(() => {
		//My code for Selected current active Disposal category
		const dispObj = {
			value: authReducer.userData?.default_disposal_list || 'Default',
			label: authReducer.userData?.default_disposal_list || 'Default',
			type: authReducer.userData?.default_disposal_type,
		};
		setDisposalCategory(dispObj);

		let disposal_data = authReducer.userData.disposal_data;
		let allDispCat = disposal_data.map(d => d.disposalType);
		allDispCat = [...new Set(allDispCat)];
		const disposalCatList = [];
		disposalCatList.push({
			label: 'master',
			options: [{ value: '', label: 'Default', type: 'master' }],
		});
		allDispCat.map(c => {
			const newObj = {};
			newObj.label = c;
			const allDispCat = disposal_data.filter(d => d.disposalType === c);
			const dispArr = [];
			allDispCat.map(d => {
				let disposalData = JSON.parse(d.disposalData);
				let allDisposalList = Object.keys(disposalData);
				allDisposalList.map(l => {
					if (disposalData[l].length) dispArr.push({ value: l, label: l, type: c });
				});
			});
			newObj.options = dispArr;
			disposalCatList.push(newObj);
		});
		setDisposalCatList(disposalCatList);
	}, [authReducer.userData.disposal_data]);

	useEffect(() => {
		if (!Array.isArray(probingIds)) return;
		if (probingIdx === -1) {
			let idx = probingIds.findIndex(p => p === selectedId);
			if (idx < 0) idx = 0;
			setProbingIdx(idx);
		}
	}, [probingIds, selectedId]);

	useEffect(() => {
		if (!Array.isArray(probingIds)) return;
		if (!probingIds[probingIdx]) return;
		const request = { probingid: probingIds[probingIdx] };
		dispatch(actions.getProbingDetail(apolloClient.probing, request));
		setLoading(true);
	}, [probingIdx]);

	useEffect(() => {
		if (!probingReducer.getProbingDetail) return;
		setLoading(false);
		setProbing(probingReducer.getProbingDetail);
		if (probingReducer.getProbingDetail.probingimage) {
			const images = JSON.parse(probingReducer.getProbingDetail.probingimage);
			const probingImages = [];
			for (const image of images) {
				const keys = Object.keys(image);
				const url = image[keys[0]];
				probingImages.push(url);
			}
			setProbingImages(probingImages);
		} else {
			setProbingImages([]);
		}
		if (probingReducer.getProbingDetail.probingaudio) {
			const audios = JSON.parse(probingReducer.getProbingDetail.probingaudio);
			const probingAudios = [];
			for (const audio of audios) {
				const keys = Object.keys(audio);
				const url = audio[keys[0]];
				probingAudios.push(url);
			}
			setProbingAudios(probingAudios);
		} else {
			setProbingAudios([]);
		}

		if (Array.isArray(probingReducer.getProbingDetail.layer)) {
			const layers = probingReducer.getProbingDetail.layer;
			const layerImages = [];
			for (let l = 0; l < layers.length; l++) {
				const layer = layers[l];
				if (layer.generalDetails.sampleImage) {
					for (const image of layer.generalDetails.sampleImage) {
						const keys = Object.keys(image);
						const url = image[keys[0]];
						layerImages.push({ layer: l, url });
					}
				}
			}
			setLayerImages(layerImages);
		}
		setActiveTab(0);
	}, [probingReducer.getProbingDetail]);

	useEffect(() => {
		const l = {};
		// colorlist from master
		const colorList = masterReducer.colorList;
		if (Array.isArray(colorList))
			if (lists?.colorList?.length !== colorList.length)
				l.colorList = getMaterialListfilter(colorList);
		// geology list from master
		const geologyList = masterReducer.geologyList;
		if (Array.isArray(geologyList))
			if (lists?.geologyList?.length !== geologyList.length)
				l.geologyList = getMaterialListfilter(geologyList);
		// lithology list from master
		const lithologyList = masterReducer.lithologyList;
		if (Array.isArray(lithologyList))
			if (lists?.lithologyList?.length !== lithologyList.length)
				l.lithologyList = getMaterialListfilter(lithologyList);
		// category list from master
		const categoryList = masterReducer.categoryList;
		if (Array.isArray(categoryList))
			if (lists?.categoryList?.length !== categoryList.length)
				l.categoryList = getMaterialListfilter(categoryList);
		// moisture list from master
		const moistureList = masterReducer.moistureList;
		if (Array.isArray(moistureList))
			if (lists?.moistureList?.length !== moistureList.length)
				l.moistureList = getMaterialListfilter(moistureList);
		// organoleptic list from master
		const organolepticList = masterReducer.organolepticList;
		if (Array.isArray(organolepticList))
			if (lists?.organolepticList?.length !== organolepticList.length)
				l.organolepticList = getMaterialListfilter(organolepticList);
		// anomaly list from master
		const anomalyList = masterReducer.anomalyList;
		if (Array.isArray(anomalyList))
			if (lists?.anomalyList?.length !== anomalyList.length)
				l.anomalyList = getMaterialListfilter(anomalyList);
		// reason list from master
		const reasonList = masterReducer.reasonList;
		if (Array.isArray(reasonList))
			if (lists?.reasonList?.length !== reasonList.length)
				l.reasonList = getMaterialListfilter(reasonList);
		// observation list from master
		const observationList = masterReducer.observationList;
		if (Array.isArray(observationList))
			if (lists?.observationList?.length !== observationList.length)
				l.observationList = getMaterialListfilter(observationList);
		if (!__.isEmpty(l)) setLists(ls => ({ ...ls, ...l }));
	}, [
		masterReducer.colorList?.length,
		masterReducer.geologyList?.length,
		masterReducer.lithologyList?.length,
		masterReducer.categoryList?.length,
		masterReducer.moistureList?.length,
		masterReducer.organolepticList?.length,
		masterReducer.anomalyList?.length,
		masterReducer.reasonList?.length,
		masterReducer.observationList?.length,
	]);

	useEffect(() => {
		if (masterReducer.disposalList) {
			let projectId = authReducer.userData.projectid;
			let projectWiseAllDisposal = masterReducer.disposalList.filter(l => {
				if (l.type === 'project' && l.projectId !== projectId) {
					return false;
				}
				return true;
			});

			setAllDisposalList(projectWiseAllDisposal);
		}
	}, [masterReducer?.disposalList?.length]);

	const getMasterName = (key, id, field = 'name') => {
		if (!key) return '-';
		if (!id) return '-';
		if (!Array.isArray(lists[key])) return '-';
		const v = lists[key].find(v => v._id === id);
		if (v) return v[field];
		return '-';
	};

	const toggleAcordion = () => {
		setExpand(prev => !prev);
	};

	function handleClickPrevious() {
		let nextIdx = probingIdx - 1;
		if (nextIdx < 0) nextIdx = probingIds.length - 1;
		setProbingIdx(nextIdx);
	}

	function handleClickNext() {
		let nextIdx = probingIdx + 1;
		if (nextIdx >= probingIds.length) nextIdx = 0;
		setProbingIdx(nextIdx);
	}
	
	const getDispOfSelCat = event => {
		if (event) {
			const default_disposal_type = event.type;
			const default_disposal_list = event.value;

			let disposals = [];
			//My code to select standard disposal from selected disposal list
			let selDispListName = default_disposal_list;
			let selAllDispListData = authReducer.userData.disposal_data.filter(
				l => l.disposalType == default_disposal_type
			);
			if (selAllDispListData.length > 0) {
				let parseDispListData = JSON.parse(selAllDispListData[0].disposalData);
				if (
					typeof parseDispListData[selDispListName] != 'undefined' &&
					parseDispListData[selDispListName].length
				) {
					let actDispData = parseDispListData[selDispListName];
					let listDisposal = [];
					actDispData.map(disposal => {
						listDisposal.push({
							value: disposal._id,
							label: disposal.name,
							color: disposal.color,
							index_no: disposal.index_no,
						});
					});

					//Assign disposals of selected disposallist
					disposals = listDisposal;
					disposals = sortBy(disposals, ['index_no']);
				} else {
					//In case of selected disposal list is empty
					disposals = sortBy(disposals, ['index_no']);
				}
			} else {
				let masterStandardDisposal = [];
				allDisposalList.map(disposal => {
					if (
						default_disposal_type === 'master' &&
						disposal.type === 'master' &&
						disposal.standard === true
					) {
						masterStandardDisposal.push({
							value: disposal._id,
							label: disposal.name,
							color: disposal.color,
							index_no: disposal.index_no,
						});
					}
				});

				//In case of Master Disposal
				disposals = sortBy(masterStandardDisposal, ['index_no']);
			}
			setDisposals(disposals);
		} else {
			setDisposals(disposals);
		}
	};

	
	function handleChange(field, event) {
		if (field === 'disposalCategory') {
			setDisposalCategory(event || {});
			setSelectedDefaultDisposalType(event?.type || '');
			setSelectedDefaultDisposalList(event?.value || '');
		}
		getDispOfSelCat(event);
	}

	function handleCloseOpen() {
		// 	let requestParams = {
		// 		projectid: state.probing.projectid,
		// 		campaignid: state.probing.campaignid,
		// 		probingid: state.probing.probingId,
		// 		reasonid: "",
		// 		reasonname: "",
		// 		detail: "",
		// 		endstatus: true,
		// 		campaignidForList: campaignidForList
		// }
		// this.props.closeProbing(this.props.apolloClient.probing, requestParams);

		console.log('close/open');
	}

	if (loading || loadingGraph)
		return (
			<div className="div-spinner">
				<Spinner size="500" className="success" />
			</div>
		);

	const customStyles = {
		menu: provided => ({
			...provided,
			zIndex: 9999,
		}),
	};

	return (
		<>
			{showHistory && (
				<ModalHistory
					isOpen={showHistory}
					onClose={() => setShowHistory(false)}
					campaignId={probing.campaignid}
					probingId={probing.probingid}
				/>
			)}
			<NavigationRow>
				{probingIds.length > 1 && (
					<Button
						className={'system-btn clear-border'}
						outline={true}
						color={'success'}
						onClick={handleClickPrevious}
						disabled={probingIdx <= 0}
					>
						<Icon>skip_previous</Icon>
					</Button>
				)}
				<div className="code">{probing.probingname || probing.probingcode}</div>
				{probingIds.length > 1 && (
					<Button
						className={'system-btn clear-border'}
						outline={true}
						color={'success'}
						onClick={handleClickNext}
						disabled={probingIdx >= probingIds.length - 1}
					>
						<Icon>skip_next</Icon>
					</Button>
				)}
			</NavigationRow>
			<Row style={{ gap: '10px', paddingBlock: '1rem' }}>
				<Col xs={12}>
					<Tooltip title={probing.endstatus ? client_Close_Probing : client_Open_Probing}>
						<span style={{ marginInlineEnd: 15 }}>
							<Button
								color="success"
								onClick={handleCloseOpen}
								className={'add-new-btn btn btn-outline-success'}
								outline={true}
								disabled={true}
							>
								<Icon>{probing.endstatus ? 'lock' : 'lock_open'}</Icon>
							</Button>
						</span>
					</Tooltip>
					<Button
						className={'add-new-btn btn btn-outline-success'}
						color={'success'}
						outline={true}
						onClick={() => setShowHistory(true)}
					>
						{todo_history}
					</Button>
					{/* <TwoLabelsSwitch
						value={display}
						onChange={value => setDisplay(value)}
						options={[
							{ label: 'Simplified', value: 's' },
							{ label: 'Complete', value: 'c' },
						]}
						fullWidth={false}
						style={{ display: 'inline-flex' }}
					/> */}
				</Col>
			</Row>
			<Row>
				<Col xs={9}>
					<Form>
						<div className="flex-row" style={{ marginBlockEnd: '0.5rem' }}>
							<ProbData label={client_probing_code} data={probing.probingcode} />
							<ProbData
								label={label_probing_name}
								data={probing.probingname || probing.probingcode}
							/>
							<ProbData label={client_probing_remarks} data={probing.remark} />
							<ProbData label={client_project_latitude} data={formatLngLat(probing.latitude)} />
							<ProbData label={client_project_longtitude} data={formatLngLat(probing.longitude)} />
							<ProbData label={client_probing_inputtext} data={probing.inputtext} />
							<ProbData
								label={client_project_altitude}
								data={formatDepth(probing.altitude, unit)}
							/>
							<ProbData
								label={`${client_probing_expecteddepth} (${unit})`}
								data={formatDepth(probing.expecteddepth, unit)}
							/>
							<ProbData
								label={`${client_probing_actual_depth} (${unit})`}
								data={formatDepth(probing.actualdepth, unit)}
							/>
							<ProbData
								label={client_probing_date}
								data={
									probing?.probingDate && moment.unix(probing?.probingDate).isValid()
										? moment(Number(probing.probingDate)).format('DD/MM/YYYY')
										: moment.utc(probing.startdate).format('DD/MM/YYYY')
								}
							/>
							<ProbData label={client_probing_field_engineer} data={probing.fieldEngineer} />
							<ProbData label={client_probing_Contact_Number} data={probing.contactnumber} />
							<ProbData label={client_probing_firstname} data={probing.firstname} />
							<ProbData label={client_probing_lastname} data={probing.lastname} />
						</div>
						<Row>
							<Col xs={12}>
								{Array.isArray(probingAudios) && probingAudios.length ? (
									<>
										<div className="data-container">
											<span className="label">{client_probing_audio_files}</span>
											{probingAudios.map((audio, idx) => (
												<audio key={`AUDIO-${idx}`} controls controlsList="nodownload">
													<source src={audio} />
												</audio>
											))}
										</div>
										<br />
									</>
								) : null}
							</Col>
							<Col xs={12}>
								{probingImages.length ? (
									<div className="data-container">
										<span className="label">{mapbox_images}</span>
										{probingImages.map((img, idx) => (
											<ImageContainer key={`IMAGE${idx}`}>
												<button onClick={() => setLightBoxUrl(img)}>
													<img src={img} alt="" />
												</button>
												<Lightbox
													images={[{ src: lightBoxUrl }]}
													isOpen={lightBoxUrl !== ''}
													showImageCount={false}
													backdropClosesModal={true}
													onClose={() => setLightBoxUrl('')}
													width={1100}
												/>
											</ImageContainer>
										))}
									</div>
								) : null}
							</Col>
						</Row>
						<br />
						<Row>
							<Col xs={12}>
								<div className="tabs tabs--bordered-top">
									<div className="tabs__wrap">
										<Nav tabs>
											{probing.layer.map((layer, idx) => (
												<NavItem key={`TAB-${idx}`}>
													<NavLink
														className={activeTab === idx ? 'active' : ''}
														onClick={() => setActiveTab(idx)}
													>
														{`${client_probing_layer} - ${idx + 1}`}
													</NavLink>
												</NavItem>
											))}
										</Nav>
										<TabContent activeTab={activeTab}>
											{probing.layer.map((layer, idx) => (
												<TabPane tabId={idx} key={`PANE-${idx}`}>
													<Row>
														<Col xs={12}>
															<div className="border_box">
																<h4>{client_viewprobing_General_Details}</h4>
																{layer?.category && (
																	<Row>
																		<Col md={6} xs={12}>
																			<div className="data-container">
																				<span className="label">{client_probing_category}</span>
																				<span className="data">
																					{layer.category.isDropDoun
																						? getMasterName(
																								'categoryList',
																								layer.category.selectedCategory
																						  )
																						: layer.category.manualCategory || '-'}
																					<TodoCheck
																						initialValue={layer.generalDetails.catisactive}
																						readOnly
																						style={{ marginInlineStart: '10px' }}
																					/>
																				</span>
																			</div>
																		</Col>
																		{layer?.generalDetails && (
																			<Col md={6} xs={12}>
																				<ProbData
																					label={client_probing_pid}
																					data={layer.generalDetails.pid}
																				/>
																			</Col>
																		)}
																	</Row>
																)}
																{layer?.generalDetails && (
																	<Row>
																		<Col md={6} xs={12}>
																			<ProbData
																				label={`${client_probing_Layer_Length} (${unit})`}
																				data={formatDepth(layer.generalDetails.layerLength, unit)}
																			/>
																		</Col>
																		{display === 's' && (
																			<ProbData
																				label={client_Moisture}
																				data={layer?.moisture?.[0]?.manualMoisture?.join(', ')}
																			/>
																		)}
																	</Row>
																)}
																<Row>
																	<Col xs={12}>
																		<span className="label">{client_probing_Layer_Image}</span>
																	</Col>
																</Row>
																{layer?.generalDetails && (
																	<Row>
																		<Col xs={12}>
																			<div className="data-container">
																				{layerImages
																					.filter(l => l.layer === activeTab)
																					.map((img, idx) => (
																						<ImageContainer key={`IMAGE-LAYER-${idx}`}>
																							<button onClick={() => setLightBoxUrl(img.url)}>
																								<img src={img.url} alt="" />
																							</button>
																							<Lightbox
																								images={[{ src: lightBoxUrl }]}
																								isOpen={lightBoxUrl !== ''}
																								showImageCount={false}
																								backdropClosesModal={true}
																								onClose={() => setLightBoxUrl('')}
																								width={1100}
																							/>
																						</ImageContainer>
																					))}
																			</div>
																		</Col>
																	</Row>
																)}
															</div>
															{layer?.lithology && (
																<div className="border_box">
																	<h4>{client_probing_lithology}</h4>
																	<Row>
																		<Col xs={2}>
																			<span className="label">{material_masters_soil_family}</span>
																		</Col>
																		{display === 'c' ? (
																			<>
																				<Col xs={2}>
																					<span className="label">{client_lithology_from}</span>
																				</Col>
																				<Col xs={2}>
																					<span className="label">
																						{material_masters_soil_family}
																					</span>
																				</Col>
																				<Col xs={2}>
																					<span className="label">{client_lithology_to}</span>
																				</Col>
																				<Col xs={2}>
																					<span className="label">{client_Anomaly}</span>
																				</Col>
																				<Col xs={2}>
																					<span className="label">{client_Active}</span>
																				</Col>
																			</>
																		) : (
																			<Col xs={2}>
																				<span className="label">{client_probing_lithology}</span>
																			</Col>
																		)}
																	</Row>
																	{layer.lithology.map((litho, idx) => {
																		const selectedAnomaly =
																			litho?.selectedanomaly?.map(a => a.label).join(', ') || '-';
																		const selectedManualAna = litho?.manualAnomaly || '-';
																		return (
																			<Row key={`LITHO-${idx}`}>
																				<Col xs={2}>
																					<span className="data">
																						{getMasterName('geologyList', litho.soil_family)}
																					</span>
																				</Col>

																				{litho.isDropDoun ? (
																					display === 'c' ? (
																						<>
																							<Col xs={2}>
																								<span className="data">
																									{getMasterName(
																										'lithologyList',
																										litho?.selectedlithologyfrom?.value
																									)}
																								</span>
																							</Col>
																							<Col xs={2}>
																								<span className="data">
																									{getMasterName(
																										'geologyList',
																										litho.soil_family_to
																									)}
																								</span>
																							</Col>
																							<Col xs={2}>
																								<span className="data">
																									{getMasterName(
																										'lithologyList',
																										litho?.selectedlithologyto?.value
																									)}
																								</span>
																							</Col>
																							<Col xs={2}>
																								<span className="data">{selectedAnomaly}</span>
																							</Col>
																						</>
																					) : (
																						<>
																							<Col xs={2}>
																								<span className="data">
																									{getMasterName(
																										'lithologyList',
																										litho?.selectedlithologyfrom?.value
																									)}
																								</span>
																							</Col>
																						</>
																					)
																				) : display === 'c' ? (
																					<>
																						<Col xs={2}>
																							<span className="data">{litho.from}</span>
																						</Col>
																						<Col xs={2}>
																							<span className="data">{litho.to}</span>
																						</Col>
																						<Col xs={2}>
																							<span className="data">{selectedManualAna}</span>
																						</Col>
																					</>
																				) : (
																					<>
																						<Col xs={2}>
																							<span className="data">{litho.from}</span>
																						</Col>
																					</>
																				)}
																				<Col xs={2}>
																					<TodoCheck initialValue={litho.isactive} readOnly />
																				</Col>
																			</Row>
																		);
																	})}
																</div>
															)}
															{layer?.color && (
																<div className="border_box">
																	<h4>{client_campaign_color}</h4>
																	<Row>
																		{display === 'c' ? (
																			<>
																				<Col xs={3}>
																					<span className="label">{client_lithology_from}</span>
																				</Col>

																				<Col xs={3}>
																					<span className="label">{client_lithology_to}</span>
																				</Col>
																				<Col xs={3}>
																					<span className="label">{client_Inclutions}</span>
																				</Col>
																			</>
																		) : (
																			<div />
																		)}
																		<Col xs={3}>
																			<span className="label">{client_Active}</span>
																		</Col>
																	</Row>
																	<Row>
																		{layer.color.map((color, idx) => (
																			<Fragment key={`COLOR-${idx}`}>
																				{color.isDropDoun ? (
																					<>
																						<Col xs={3}>
																							<span className="data inline-flex">
																								{getMasterName(
																									'colorList',
																									color.selectedcolorfrom?.value
																								)}
																								<ColorBox
																									color={getMasterName(
																										'colorList',
																										color.selectedcolorfrom?.value,
																										'value'
																									)}
																								/>
																							</span>
																						</Col>

																						{display === 'c' && (
																							<>
																								<Col xs={3}>
																									<span className="data inline-flex">
																										{getMasterName(
																											'colorList',
																											color?.selectedcolorto?.value
																										)}
																										<ColorBox
																											color={getMasterName(
																												'colorList',
																												color?.selectedcolorto?.value,
																												'value'
																											)}
																										/>
																									</span>
																								</Col>
																								<Col xs={3}>
																									<span className="data inline-flex">
																										{getMasterName(
																											'colorList',
																											color?.selectedcolorinclutions?.value
																										)}
																										<ColorBox
																											color={getMasterName(
																												'colorList',
																												color?.selectedcolorinclutions?.value,
																												'value'
																											)}
																										/>
																									</span>
																								</Col>
																							</>
																						)}
																					</>
																				) : (
																					<>
																						<Col xs={3}>
																							<span className="data inline-flex">
																								{color.fromname}
																								<ColorBox color={color.from} />
																							</span>
																						</Col>

																						{display === 'c' && (
																							<>
																								<Col xs={3}>
																									<span className="data inline-flex">
																										{color.toname}
																										<ColorBox color={color.to} />
																									</span>
																								</Col>
																								<Col xs={3}>
																									<span className="data inline-flex">
																										{color.inclutionsname}
																										<ColorBox color={color.inclutions} />
																									</span>
																								</Col>
																							</>
																						)}
																					</>
																				)}

																				<Col xs={3}>
																					<TodoCheck initialValue={color.isactive} readOnly />
																				</Col>
																			</Fragment>
																		))}
																	</Row>
																</div>
															)}
															{display === 'c' && layer?.moisture && (
																<div className="border_box">
																	<h4 style={{ display: 'flex', alignItems: 'center' }}>
																		{client_Moisture}
																		<TodoCheck
																			initialValue={layer.moisture[0].isactive}
																			readOnly
																			style={{ marginInlineStart: '10px' }}
																		/>
																	</h4>
																	<Row>
																		{layer.moisture.map((moisture, idx) => {
																			const selected = Array.isArray(moisture?.selectedmoisturelist)
																				? moisture.selectedmoisturelist
																						.map(a => a.label)
																						.join(', ') || '-'
																				: moisture?.selectedmoisturelist || '-';
																			return (
																				<Fragment key={`MOISTURE-${idx}`}>
																					<Col md={6} xs={12}>
																						{moisture.isDropDoun ? (
																							<div className="data-container">
																								<span className="label">{client_Moisture}</span>
																								<span className="data">{selected}</span>
																							</div>
																						) : (
																							<ProbData
																								label={client_Moisture}
																								data={moisture?.manualMoisture.join(', ')}
																							/>
																						)}
																					</Col>

																					{moisture.isactive && (
																						<Col md={6} xs={12}>
																							<ProbData label={'Todo Note'} data={moisture.value} />
																						</Col>
																					)}
																				</Fragment>
																			);
																		})}
																	</Row>
																</div>
															)}
															{layer?.observation && (
																<div className="border_box">
																	<h4 style={{ display: 'flex', alignItems: 'center' }}>
																		{client_Observation}
																		<TodoCheck
																			initialValue={layer.observation.isactive}
																			readOnly
																			style={{ marginInlineStart: '10px' }}
																		/>
																	</h4>
																	<Row>
																		<Col md={6} xs={12}>
																			<ProbData
																				label={client_Observation}
																				data={layer.observation.value}
																			/>
																		</Col>
																	</Row>
																</div>
															)}
															{layer.sample.map((sample, idx) => {
																const si = sampleInfo.find(s => s.sample_name === sample.sampleNo);
																const desp = si?.matched_desp;
																let sampleIsManual = !!sample.sampleNameIsManual;
																const sampleName =
																	sample?.sampleName ||
																	`${
																		sampleIsManual
																			? sample.sampleNameManual || '-'
																			: probing.probingcode
																	}${
																		!isNaN(sample.sampleNameTop) && !isNaN(sample.sampleNameBottom)
																			? ` (${sample.sampleNameTop} - ${sample.sampleNameBottom})`
																			: ''
																	}`;
																return (
																	<Row key={`SAMPLE-${idx}`}>
																		<Col className="margin__top" xs={12}>
																			<Accordion style={{ padding: '0px' }}>
																				<AccordionSummary
																					expandIcon={<ExpandMoreIcon />}
																					aria-controls="panel1a-content"
																					id="panel1a-header"
																				>
																					<Row>
																						<Col xs={2}>
																							<ProbData
																								label={client_sample_number}
																								data={sample.sampleNo || '-'}
																								column
																							/>
																						</Col>
																						<Col xs={2}>
																							<ProbData
																								label={client_Depth}
																								data={
																									sample.sample_depth
																										? formatDepth(sample.sample_depth, unit)
																										: '-'
																								}
																								column
																							/>
																						</Col>
																						<Col xs={2}>
																							<ProbData
																								label={client_probing_depth_in_probing}
																								data={
																									sample.sample_depth_in_probing
																										? formatDepth(
																												sample.sample_depth_in_probing,
																												unit
																										  )
																										: '-'
																								}
																								column
																							/>
																						</Col>
																						<Col xs={2}>
																							<ProbData
																								label={client_Length}
																								data={
																									sample.sample_length
																										? formatDepth(sample.sample_length, unit)
																										: '-'
																								}
																								column
																							/>
																						</Col>
																						<Col xs={2}>
																							<ProbData
																								label={client_probing_comment}
																								data={sample.comments || '-'}
																								column
																							/>
																						</Col>
																						<Col xs={2}>
																							<ProbData
																								label={client_probing_sample_altitude}
																								data={
																									sample.middel_depth
																										? formatDepth(sample.middel_depth, unit)
																										: '-'
																								}
																								column
																							/>
																						</Col>
																					</Row>
																				</AccordionSummary>
																				<AccordionDetails>
																					<Row>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_sample_number}
																								data={sample.sampleNo}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={`${client_probing_sample_altitude} (${unit})`}
																								data={
																									sample.middel_depth
																										? formatDepth(sample.middel_depth, unit)
																										: '-'
																								}
																							/>
																						</Col>

																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_sample_name}
																								data={sampleName}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_probing_depth_in_probing}
																								data={
																									sample.sample_depth_in_probing
																										? formatDepth(
																												sample.sample_depth_in_probing,
																												unit
																										  )
																										: '-'
																								}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							{idx === 0 && layer.sample.length <= 1 && (
																								<div className="data-container">
																									<span className="label">
																										{client_probing_complete_layer}
																									</span>
																									<span className="data">
																										<Switch
																											style={{
																												color: sample.isCompleteLayer
																													? '#1098F7'
																													: '',
																											}}
																											checked={sample.isCompleteLayer}
																											name="isCompleteLayer"
																											color="primary"
																											disabled
																										/>
																									</span>
																								</div>
																							)}
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_probing_comment}
																								data={sample.comments}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_Length}
																								data={
																									sample.sample_length
																										? formatDepth(sample.sample_length, unit)
																										: '-'
																								}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_Depth}
																								data={
																									sample.sample_depth
																										? formatDepth(sample.sample_depth, unit)
																										: '-'
																								}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_probing_upper_depth}
																								data={
																									sample.upper_depth
																										? formatDepth(sample.upper_depth, unit)
																										: '-'
																								}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_probing_lower_depth}
																								data={
																									sample.lower_depth
																										? formatDepth(sample.lower_depth, unit)
																										: '-'
																								}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={'Time of sampling'}
																								data={moment(sample.samplingTime).format(
																									'DD/MM/YYYY HH:mm'
																								)}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<div className="data-container">
																								<span className="label">{laboratory_txt}</span>
																								<span className="data">
																									<TodoCheck
																										initialValue={sample.labactive}
																										readOnly
																									/>
																								</span>
																							</div>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_campaign_reference}
																								data={sample.reference}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_probing_remark}
																								data={sample.remark}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							<ProbData
																								label={client_Observation}
																								data={sample.observation}
																							/>
																						</Col>
																						<Col xs={12} sm={6} md={4} lg={3}>
																							{desp && !__.isEmpty(desp) && (
																								<div className="data-container">
																									<span className="label">{client_disposal}</span>
																									<span className="data">
																										<LegendItem value={desp} />
																									</span>
																								</div>
																							)}
																						</Col>
																					</Row>
																				</AccordionDetails>
																			</Accordion>
																		</Col>
																	</Row>
																);
															})}
														</Col>
													</Row>
												</TabPane>
											))}
										</TabContent>
										<br></br>
										<Row>
											<Col xs={12} sm={12}>
												<Accordion expanded={expand} onChange={toggleAcordion}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1a-content"
														IconButtonProps={
															{
																//onClick: toggleAcordion
															}
														}
														style={{ padding: '0' }}
													>
														<ViewAnalysisSection>{client_view_analysis_report}</ViewAnalysisSection>
													</AccordionSummary>
													<AccordionDetails>
														{!__.isEmpty(reportData) && expand && (
															<Reports>
																<DisposalContainer>
																	<Select2
																		options={disposalCatList}
																		formatGroupLabel={formatGroupLabel}
																		isMulti={false}
																		name="dispcategory"
																		placeholder={client_probing_category}
																		value={disposalCategory ? disposalCategory : {}}
																		onChange={e => handleChange('disposalCategory', e)}
																		isOptionSelected={(option, selectValue) =>
																			selectValue.some(
																				i => JSON.stringify(i) === JSON.stringify(option)
																			)
																		}
																		styles={customStyles}
																		closeMenuOnSelect={true}
																		className="basic-multi-select"
																		classNamePrefix="select "
																		isSearchable={true}
																		isClearable={false}
																	/>
																</DisposalContainer>

																<AllButtonsContainer>
																	<ReportButtonsContainer>
																		<MapButtonGroup
																			title={client_values_above_minimum_value_toggle}
																			buttons={[
																				{
																					id: 'flt-above-minimum-value',
																					tooltip: client_values_above_minimum_value_toggle,
																					switch: true,
																					onClick: checked => setvalGreToFirstDisThrshold(checked),
																					checked: valGreToFirstDisThrshold,
																					visible: true,
																					isDisabled: false,
																				},
																			]}
																		/>
																	</ReportButtonsContainer>

																	<ReportButtonsContainer>
																		<MapButtonGroup
																			title={analysis_all_pollutants}
																			buttons={[
																				{
																					id: 'flt-all-pollutants',
																					tooltip: analysis_all_pollutants,
																					switch: true,
																					onClick: checked => setAllPollutants(checked),
																					checked: allPollutants,
																					visible: true,
																					isDisabled: false,
																				},
																			]}
																		/>
																	</ReportButtonsContainer>

																	<ReportButtonsContainer>
																		<MapButtonGroup
																			title={analysis_more_header_details}
																			buttons={[
																				{
																					id: 'flt-hedr-detl',
																					tooltip: analysis_more_header_details,
																					switch: true,
																					onClick: checked => setLockHeader(checked),
																					checked: lockHeader,
																					visible: true,
																					isDisabled: false,
																				},
																			]}
																		/>
																	</ReportButtonsContainer>

																	<ReportButtonsContainer>
																		<MapButtonGroup
																			title={analysis_wider_columns}
																			buttons={[
																				{
																					id: 'flt-wider-col',
																					tooltip: analysis_wider_columns,
																					switch: true,
																					onClick: checked => setWiderColumns(checked),
																					checked: widerColumns,
																					visible: true,
																					isDisabled: false,
																				},
																			]}
																		/>
																	</ReportButtonsContainer>
																</AllButtonsContainer>

																<AllButtonsContainer>
																	<ReportButtonsContainer>
																		<MapButtonGroup
																			title={search_text}
																			buttons={[
																				{
																					id: 'flt-search-smpl',
																					tooltip: search_text,
																					switch: true,
																					onClick: checked => {
																						setSearchSample(checked);
																						if (!checked) {
																							searchValue.current.value = '';
																							setFindSearchValue('');
																						}
																					},
																					checked: searchSample,
																					visible: true,
																					isDisabled: false,
																				},
																			]}
																		/>
																	</ReportButtonsContainer>
																	{searchSample && (
																		<ReportButtonsContainer>
																			<Input
																				type="text"
																				className="searchInput"
																				innerRef={searchValue}
																			/>
																			<Button
																				className="searchBtn"
																				color="success"
																				size="sm"
																				onClick={e =>
																					setFindSearchValue(searchValue.current?.value.trim())
																				}
																			>
																				<Icon>send</Icon>
																			</Button>
																		</ReportButtonsContainer>
																	)}
																</AllButtonsContainer>

																<AnalysisReportsTable reportData={reportData} />
															</Reports>
														)}
														{__.isEmpty(reportData) && expand && (
															<NoAnalysisFound>
																{client_no_analysis_found_for_probing}
															</NoAnalysisFound>
														)}
													</AccordionDetails>
												</Accordion>
											</Col>
										</Row>
									</div>
								</div>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col xs={3}>
					<div style={{ position: 'sticky', top: '10px' }}>
						<HighchartsReact highcharts={Highcharts} options={chartData} />
					</div>
				</Col>
			</Row>
		</>
	);
};

const ProbData = ({ label, data, column }) => {
	return (
		<div className={`data-container ${column ? 'column' : ''}`}>
			<span className="label">{label}</span>
			<span className="data">{data}</span>
		</div>
	);
};

function RenderInNewWindow({ children, title, onClose }) {
	const newWindow = useRef(window);
	const [container, setContainer] = useState(null);

	useEffect(() => {
		const div = document.createElement('div');
		setContainer(div);
	}, []);

	useEffect(() => {
		if (container) {
			newWindow.current = window.open('about:blank', title, 'width=800,height=600,left=0,top=0');
			const curWindow = newWindow.current;
			curWindow.document.body.appendChild(container);

			const stylesheets = Array.from(document.styleSheets);
			stylesheets.forEach(stylesheet => {
				const css = stylesheet;

				if (stylesheet.href) {
					const newStyleElement = document.createElement('link');
					newStyleElement.rel = 'stylesheet';
					newStyleElement.href = stylesheet.href;
					curWindow.document.head.appendChild(newStyleElement);
				} else if (css && css.cssRules && css.cssRules.length > 0) {
					const newStyleElement = document.createElement('style');
					Array.from(css.cssRules).forEach(rule => {
						newStyleElement.appendChild(document.createTextNode(rule.cssText));
					});
					curWindow.document.head.appendChild(newStyleElement);
				}
			});

			curWindow.onbeforeunload = () => onClose();
			curWindow.onresize = () => {};

			return () => curWindow.close();
		}
	}, [container]);

	return container ? createPortal(children, container) : null;
}

const Reports = styled.div``;

const DisposalContainer = styled.div`
	width: 38%;
	margin-bottom: 10px;
`;

const ViewAnalysisSection = styled.div`
	font-size: 18px;
	line-height: 24px;
	color: var(--clr-aexdo);
`;

const NoAnalysisFound = styled.div`
	font-size: 18px;
	line-height: 24px;
	color: var(--clr-aexdo);
`;

const MinimumValueToggle = styled.div`
	color: var(--clr-aexdo);
	display: inline-block;
`;

const ImageContainer = styled.div`
	button {
		border: none;
		background: none;
	}
	img {
		min-height: 70px;
		width: 120px;
		height: 70px;
		border: 'solid 1px #dadada';
		z-index: 1;
		margin-inline-end: 2px;
	}
`;

const ColorBox = styled.div`
	width: 1.5em;
	height: 1.5em;
	background-color: ${props => props.color};
	border-radius: 50%;
	border: 1px solid #bbb;
`;

const NavigationRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-size: 1.2rem;
	.btn {
		margin: 0;
	}
	.code {
		font-size: 1.5rem;
	}
`;

const ReportButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-bottom: 5px;
	gap: 10px;
	align-items: stretch;
	.searchBtn {
		width: auto;
		height: 26px;
		padding: 2px 15px;
		margin: 0px;
	}
	.searchInput {
		height: calc(1em + 0.9rem);
	}
`;

const AllButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-bottom: 5px;
	gap: 10px;
	align-items: stretch;
`;
